import React from 'react';
import './Awards.css';

const Awards = () => {
  return (
    <div>

    </div>
  );
};

export default Awards;
