import React from 'react';
import './Skills.css';

const Skills = () => {
  return (
    <div>

    </div>
  );
};

export default Skills;
