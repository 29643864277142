import React from 'react';
import './Education.css';

const Education = () => {
  return (
    <div>

    </div>
  );
};

export default Education;
